import { trigger, state, animate, transition, style } from '@angular/animations';

export const slideInOutAnimation =
  trigger('slideInOutAnimation', [
    state('*', style({
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 1
    })),

    transition(':enter', [
      style({
        left: '-400%',
        opacity: 0
      }),

      animate('.5s ease-in-out', style({
        right: 0,
        opacity: 1
      }))
    ]),

    transition(':leave', [
      animate('.5s ease-in-out', style({
        left: '-400%',
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }))
    ])
  ]);

  trigger('rotate360Animation', [
  state('*', style({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: '11.3vh',
    height: '100vh',
    width: 'calc(100% - 11.3vh)',
    opacity: 1
  })),

  transition(':enter', [
    style({
      top: 0,
      left: 0,
      height: '11vh',
      width: '11vh',
      opacity: 0,
      transform: 'rotate(-360deg)'
    }),

    animate('.5s ease-in-out', style({
      height: '100vh',
      width: 'calc(100% - 11.3vh)',
      opacity: 1,
      transform: 'rotate(360deg)'
    }))
  ]),

  transition(':leave', [
    animate('.5s ease-in-out', style({
      left: '-400%',
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }))
  ])
]);
