<footer role="contentinfo">
  <div class="_circles"></div>
  <div class="count-container">
    <div class="count-age">
      <span class="left" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
      <span class="right" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
    </div>
    <div class="count-xp">
     <!--
      <span class="left" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
      <span class="right" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
      -->
    </div>
    <div class="count-project">
      <!--
     <span class="left" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
     <span class="right" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
      -->
   </div>
   <div class="count-passion">
    <!--
     <span class="left" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
     <span class="right" *ngFor="let num of [0,1,2,3,4,5,6,7,8,9]"></span>
      -->
   </div>
 </div>
</footer>
