import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import * as THREE from 'three';
import gsap from "gsap";
import { AudioService } from '../../services/audio.service';

@Component({
  selector: 'el-piano-app',
  templateUrl: './piano-app.component.html',
  styleUrls: ['./piano-app.component.scss']
})
export class PianoAppComponent implements AfterViewInit {

  zindex: string = 'z-index-1';

  @ViewChild('canvas')
  private canvasRef: ElementRef;

  private musicPlaying: boolean = false;

  constructor(private audioService: AudioService) {
  }

  ngOnInit(): void {
  }

  public playPiano = () => {
    Promise.resolve(this.canvasRef)
      .then(this.animatePiano)
      .then(this.onOffSound)
  }

  private audioBtnOnOff = () => {
    const audioBtnClass: string = document.getElementById('audioBtn').className;

    if (this.musicPlaying) {
      document.getElementById('audioBtn').className = audioBtnClass.replace('z-index-1', 'z-index-0');
    } else {
      document.getElementById('audioBtn').className = audioBtnClass.replace('z-index-0', 'z-index-1');
    }
  }

  private iconMusic = () => {
    const iconMusic: string = document.getElementById('icon-music').className;

    if (this.musicPlaying) {
      document.getElementById('icon-music').className = iconMusic.replace('play', 'music');
    } else {
      document.getElementById('icon-music').className = iconMusic.replace('music', 'play');
    }
  }

  public onOffSound() {
    this.audioBtnOnOff();
    this.iconMusic();
  }

  /** Helper Properties (private properties **/

  private camera!: THREE.PerspectiveCamera;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  /** Piano Properties **/

  public touchX: number = 2.3;
  public touchZ: number = 13.8;
  public touchY: number = 1;
  public touchPositionY: number = 0;
  public sharpX: number = 0.9;
  public sharpZ: number = 7.8;
  public sharpY: number = 1;
  public sharpPositionY: number = 0.5;
  public touchInt: number = 0.1;
  public touchColor: number = 0xffffff;
  public sharpColor: number = 0x072d48;
  public intColor: number = 0x434343;
  public touchDownZ: number = this.touchZ - this.sharpZ - this.touchInt;
  public touchUpZ: number = this.sharpZ + this.touchInt;
  public touch1UpX: number = this.touchX + this.touchInt / 2 - this.sharpX / 2 - this.touchInt;
  public touch2UpX: number = this.touchX + this.touchInt - this.sharpX - this.touchInt * 2;

  private piano: THREE.Group = new THREE.Group();

  private touchDown: THREE.Mesh = new THREE.Mesh(
    new THREE.BoxGeometry(this.touchX, this.touchY, this.touchDownZ),
    new THREE.MeshBasicMaterial({ color: this.touchColor })
  );

  private touch1Up = new THREE.Mesh(
    new THREE.BoxGeometry(this.touch1UpX, this.touchY, this.touchUpZ),
    new THREE.MeshBasicMaterial({ color: this.touchColor })
  );

  private touch2Up = new THREE.Mesh(
    new THREE.BoxGeometry(this.touch2UpX, this.touchY, this.touchUpZ),
    new THREE.MeshBasicMaterial({ color: this.touchColor })
  );

  private touchDownInt = new THREE.Mesh(
    new THREE.BoxGeometry(this.touchInt, this.touchY, this.touchDownZ),
    new THREE.MeshBasicMaterial({ color: this.intColor })
  );

  private touchFullInt = new THREE.Mesh(
    new THREE.BoxGeometry(this.touchInt, this.touchY, this.touchZ),
    new THREE.MeshBasicMaterial({ color: this.intColor })
  );

  private sharpUp = new THREE.Mesh(
    new THREE.BoxGeometry(this.sharpX, this.sharpY, this.sharpZ),
    new THREE.MeshBasicMaterial({ color: this.sharpColor })
  );

  private sharpBefore = new THREE.Mesh(
    new THREE.BoxGeometry(this.touchInt, this.touchY, this.touchUpZ),
    new THREE.MeshBasicMaterial({ color: this.intColor })
  );

  private sharpDown = new THREE.Mesh(
    new THREE.BoxGeometry(this.sharpX, this.touchY, this.touchInt),
    new THREE.MeshBasicMaterial({ color: this.intColor })
  );

  private sharpAfter = new THREE.Mesh(
    new THREE.BoxGeometry(this.touchInt, this.touchY, this.touchUpZ),
    new THREE.MeshBasicMaterial({ color: this.intColor })
  );

  private fullTouch = new THREE.Mesh(
    new THREE.BoxGeometry(this.touchX, this.touchY, this.touchZ),
    new THREE.MeshBasicMaterial({ color: this.touchColor })
  );

  /**
   * Octave-1
   */

  private la_1: THREE.Group = new THREE.Group();
  private la_1Sharp: THREE.Group = new THREE.Group();
  private si_1: THREE.Group = new THREE.Group();

  private octave_1() {
    // LA-1
    const la_1Down = this.touchDown.clone();
    la_1Down.position.set(-(this.touchX*1.5 + this.touchInt*2), this.touchPositionY, this.touchZ - this.touchDownZ/2);
    const la_1Up = this.touch1Up.clone();
    la_1Up.position.set(-((this.touchX+this.touchInt)*2 - this.touch1UpX/2), this.touchPositionY, this.touchUpZ/2);
    const la_1Touch = new THREE.Group().add(la_1Down, la_1Up);
    la_1Touch.scale.set(.5, .5, .5);
    la_1Touch.rotation.x = 1;
    // LA-1 - Int
    const la_1TouchIntBefore = this.touchFullInt.clone();
    la_1TouchIntBefore.position.set(-(this.touchX*2 + this.touchInt*2.5), this.touchPositionY, this.touchZ/2);
    const la_1Si_1TouchInt = this.touchDownInt.clone();
    la_1Si_1TouchInt.position.set(-(this.touchX + this.touchInt*1.5), this.touchPositionY, this.touchZ - this.touchDownZ/2);
    const la_1SharpBefore = this.sharpBefore.clone();
    la_1SharpBefore.position.set(-(this.touchX + this.touchInt*2 + this.sharpX/2), this.touchPositionY, this.touchUpZ/2);
    const la_1SharpDown = this.sharpDown.clone();
    la_1SharpDown.position.set(-(this.touchX + this.touchInt*1.5), this.touchPositionY, this.sharpZ+this.touchInt/2);
    const la_1SharpAfter = this.sharpDown.clone();
    la_1SharpAfter.position.set(-(this.touchX + this.touchInt - this.sharpX/2), this.touchPositionY, this.touchUpZ/2);
    const la_1Int = new THREE.Group().add(la_1TouchIntBefore, la_1Si_1TouchInt, la_1SharpBefore, la_1SharpDown, la_1SharpAfter);
    la_1Int.scale.set(.5, .5, .5);
    la_1Int.rotation.x = 1;
    const la_1_ = new THREE.Group().add(la_1Touch, la_1Int);
    la_1_.name = 'la-1';

    // SI-1
    const si_1Down = this.touchDown.clone();
    si_1Down.position.set(-(this.touchInt + this.touchX/2), this.touchPositionY, this.touchZ - this.touchDownZ/2);
    const si_1Up = this.touch1Up.clone();
    si_1Up.position.set(-(this.touchInt+this.touch1UpX/2), this.touchPositionY, this.touchUpZ/2);
    const si_1Touch = new THREE.Group().add(si_1Down, si_1Up);
    si_1Touch.scale.set(.5, .5, .5);
    si_1Touch.rotation.x = 1;
    // SI-1 - Int
    const si_1Do0TouchInt = this.touchFullInt.clone();
    si_1Do0TouchInt.position.set(-this.touchInt/2, this.touchPositionY, this.touchZ/2);
    const si_1Int = new THREE.Group().add(si_1Do0TouchInt);
    si_1Int.scale.set(.5, .5, .5);
    si_1Int.rotation.x = 1;
    const si_1_ = new THREE.Group().add(si_1Touch, si_1Int);
    si_1_.name = 'si-1';

    // LA_1_SHARP
    const la_1SharpUp = this.sharpUp.clone();
    la_1SharpUp.position.set(-(this.touchX+this.touchInt*1.5), this.sharpPositionY, this.sharpZ/2);
    const la_1SharpTouch = new THREE.Group().add(la_1SharpUp);
    la_1SharpTouch.scale.set(.5, .5, .5);
    la_1SharpTouch.rotation.x = 1;
    const sharps_1Sharp = new THREE.Group().add(la_1SharpTouch);
    const sharps_1 = new THREE.Group().add(sharps_1Sharp);
    sharps_1.name = 'sharps-1';

    this.la_1 = la_1Touch;
    this.la_1Sharp = la_1SharpUp;
    this.si_1 = si_1Touch;

    const octave_1: THREE.Group = new THREE.Group().add(la_1_, si_1_, sharps_1);
    octave_1.name = 'octave_1';

    this.piano.add(octave_1);
    this.piano.name = 'piano';
  }

  /**
   * Octaves
   * @private
   */

  private octave0: THREE.Group = new THREE.Group();
  private do0: THREE.Group = new THREE.Group();
  private do0Sharp: THREE.Group = new THREE.Group();
  private re0: THREE.Group = new THREE.Group();
  private re0Sharp: THREE.Group = new THREE.Group();
  private mi0: THREE.Group = new THREE.Group();
  private fa0: THREE.Group = new THREE.Group();
  private fa0Sharp: THREE.Group = new THREE.Group();
  private sol0: THREE.Group = new THREE.Group();
  private sol0Sharp: THREE.Group = new THREE.Group();
  private la0: THREE.Group = new THREE.Group();
  private la0Sharp: THREE.Group = new THREE.Group();
  private si0: THREE.Group = new THREE.Group();

  private octave1: THREE.Group = new THREE.Group();
  private do1: THREE.Group = new THREE.Group();
  private do1Sharp: THREE.Group = new THREE.Group();
  private re1: THREE.Group = new THREE.Group();
  private re1Sharp: THREE.Group = new THREE.Group();
  private mi1: THREE.Group = new THREE.Group();
  private fa1: THREE.Group = new THREE.Group();
  private fa1Sharp: THREE.Group = new THREE.Group();
  private sol1: THREE.Group = new THREE.Group();
  private sol1Sharp: THREE.Group = new THREE.Group();
  private la1: THREE.Group = new THREE.Group();
  private la1Sharp: THREE.Group = new THREE.Group();
  private si1: THREE.Group = new THREE.Group();

  private octave2: THREE.Group = new THREE.Group();
  private do2: THREE.Group = new THREE.Group();
  private do2Sharp: THREE.Group = new THREE.Group();
  private re2: THREE.Group = new THREE.Group();
  private re2Sharp: THREE.Group = new THREE.Group();
  private mi2: THREE.Group = new THREE.Group();
  private fa2: THREE.Group = new THREE.Group();
  private fa2Sharp: THREE.Group = new THREE.Group();
  private sol2: THREE.Group = new THREE.Group();
  private sol2Sharp: THREE.Group = new THREE.Group();
  private la2: THREE.Group = new THREE.Group();
  private la2Sharp: THREE.Group = new THREE.Group();
  private si2: THREE.Group = new THREE.Group();

  private octave3: THREE.Group = new THREE.Group();
  private do3: THREE.Group = new THREE.Group();
  private do3Sharp: THREE.Group = new THREE.Group();
  private re3: THREE.Group = new THREE.Group();
  private re3Sharp: THREE.Group = new THREE.Group();
  private mi3: THREE.Group = new THREE.Group();
  private fa3: THREE.Group = new THREE.Group();
  private fa3Sharp: THREE.Group = new THREE.Group();
  private sol3: THREE.Group = new THREE.Group();
  private sol3Sharp: THREE.Group = new THREE.Group();
  private la3: THREE.Group = new THREE.Group();
  private la3Sharp: THREE.Group = new THREE.Group();
  private si3: THREE.Group = new THREE.Group();

  private octave4: THREE.Group = new THREE.Group();
  private do4: THREE.Group = new THREE.Group();
  private do4Sharp: THREE.Group = new THREE.Group();
  private re4: THREE.Group = new THREE.Group();
  private re4Sharp: THREE.Group = new THREE.Group();
  private mi4: THREE.Group = new THREE.Group();
  private fa4: THREE.Group = new THREE.Group();
  private fa4Sharp: THREE.Group = new THREE.Group();
  private sol4: THREE.Group = new THREE.Group();
  private sol4Sharp: THREE.Group = new THREE.Group();
  private la4: THREE.Group = new THREE.Group();
  private la4Sharp: THREE.Group = new THREE.Group();
  private si4: THREE.Group = new THREE.Group();

  private octave5: THREE.Group = new THREE.Group();
  private do5: THREE.Group = new THREE.Group();
  private do5Sharp: THREE.Group = new THREE.Group();
  private re5: THREE.Group = new THREE.Group();
  private re5Sharp: THREE.Group = new THREE.Group();
  private mi5: THREE.Group = new THREE.Group();
  private fa5: THREE.Group = new THREE.Group();
  private fa5Sharp: THREE.Group = new THREE.Group();
  private sol5: THREE.Group = new THREE.Group();
  private sol5Sharp: THREE.Group = new THREE.Group();
  private la5: THREE.Group = new THREE.Group();
  private la5Sharp: THREE.Group = new THREE.Group();
  private si5: THREE.Group = new THREE.Group();

  private octave6: THREE.Group = new THREE.Group();
  private do6: THREE.Group = new THREE.Group();
  private do6Sharp: THREE.Group = new THREE.Group();
  private re6: THREE.Group = new THREE.Group();
  private re6Sharp: THREE.Group = new THREE.Group();
  private mi6: THREE.Group = new THREE.Group();
  private fa6: THREE.Group = new THREE.Group();
  private fa6Sharp: THREE.Group = new THREE.Group();
  private sol6: THREE.Group = new THREE.Group();
  private sol6Sharp: THREE.Group = new THREE.Group();
  private la6: THREE.Group = new THREE.Group();
  private la6Sharp: THREE.Group = new THREE.Group();
  private si6: THREE.Group = new THREE.Group();

  private octaves() {
    let octavePosition = 0;

    for ( let i = 0; i < 7; i++ ) {
      // DO
      const doDown = this.touchDown.clone();
      doDown.position.set(this.touchX/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const doUp = this.touch1Up.clone();
      doUp.position.set(this.touch1UpX/2, this.touchPositionY, this.touchUpZ/2);
      const doTouch = new THREE.Group().add(doDown, doUp);
      doTouch.scale.set(.5, .5, .5);
      doTouch.rotation.x = 1;
      // DO - Int
      const doReTouchInt = this.touchDownInt.clone();
      doReTouchInt.position.set(this.touchX+this.touchInt/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const doSharpBefore = this.sharpBefore.clone();
      doSharpBefore.position.set(this.touchX-this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const doSharpDown = this.sharpDown.clone();
      doSharpDown.position.set(this.touchX+this.touchInt/2, this.touchPositionY, this.sharpZ+this.touchInt/2);
      const doSharpAfter = this.sharpAfter.clone();
      doSharpAfter.position.set(this.touchX+this.touchInt+this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const doInt = new THREE.Group().add(doReTouchInt, doSharpBefore, doSharpDown, doSharpAfter);
      doInt.scale.set(.5, .5, .5);
      doInt.rotation.x = 1;
      const do_ = new THREE.Group().add(doTouch, doInt);
      do_.name = `do${i}`;

      // RE
      const reDown = this.touchDown.clone();
      reDown.position.set(this.touchX+this.touchInt+this.touchX/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const reUp = this.touch2Up.clone();
      reUp.position.set(this.touchX+this.touchInt*1.5+this.sharpX/2+this.touch2UpX/2, this.touchPositionY, this.touchUpZ/2);
      const reTouch = new THREE.Group().add(reDown, reUp);
      reTouch.scale.set(.5, .5, .5);
      reTouch.rotation.x = 1;
      // RE - Int
      const reMiTouchInt = this.touchDownInt.clone();
      reMiTouchInt.position.set(this.touchX*2+this.touchInt*1.5, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const reSharpBefore = this.sharpBefore.clone();
      reSharpBefore.position.set(this.touchX*2+this.touchInt - this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const reSharpDown = this.sharpBefore.clone();
      reSharpDown.position.set(this.touchX*2+this.touchInt*1.5, this.touchPositionY, this.sharpZ+this.touchInt/2);
      const reSharpAfter = this.sharpAfter.clone();
      reSharpAfter.position.set((this.touchX+this.touchInt)*2 + this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const reInt = new THREE.Group().add(reMiTouchInt, reSharpBefore, reSharpDown, reSharpAfter);
      reInt.scale.set(.5, .5, .5);
      reInt.rotation.x = 1;
      const re_ = new THREE.Group().add(reTouch, reInt);
      re_.name = `re${i}`;

      // MI
      const miDown = this.touchDown.clone();
      miDown.position.set((this.touchX+this.touchInt)*2+this.touchX/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const miUp = this.touch1Up.clone();
      miUp.position.set(this.touchX*2 + this.touchInt*2.5 + this.sharpX/2 + this.touch1UpX/2, this.touchPositionY, this.touchUpZ/2);
      const miTouch = new THREE.Group().add(miDown, miUp);
      miTouch.scale.set(.5, .5, .5);
      miTouch.rotation.x = 1;
      // MI - Int
      const miFaTouchInt = this.touchFullInt.clone();
      miFaTouchInt.position.set(this.touchX*3+this.touchInt*2.5, this.touchPositionY, this.touchZ/2);
      const miInt = new THREE.Group().add(miFaTouchInt);
      miInt.scale.set(.5, .5, .5);
      miInt.rotation.x = 1;
      const mi_ = new THREE.Group().add(miTouch, miInt);
      mi_.name = `mi${i}`;

      // FA
      const faDown = this.touchDown.clone();
      faDown.position.set((this.touchX+this.touchInt)*3 + this.touchX/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const faUp = this.touch1Up.clone();
      faUp.position.set((this.touchX+this.touchInt)*3 + this.touch1UpX/2, this.touchPositionY, this.touchUpZ/2);
      const faTouch = new THREE.Group().add(faDown, faUp);
      faTouch.scale.set(.5, .5, .5);
      faTouch.rotation.x = 1;
      // FA - Int
      const faSolTouchInt = this.touchDownInt.clone();
      faSolTouchInt.position.set(this.touchX*4+this.touchInt*3.5, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const faSharpBefore = this.sharpBefore.clone();
      faSharpBefore.position.set(this.touchX*4 + this.touchInt*3 - this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const faSharpDown = this.sharpDown.clone();
      faSharpDown.position.set(this.touchX*4 + this.touchInt*3.5, this.touchPositionY, this.sharpZ+this.touchInt/2);
      const faSharpAfter = this.sharpAfter.clone();
      faSharpAfter.position.set((this.touchX+this.touchInt)*4 + this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const faInt = new THREE.Group().add(faSolTouchInt, faSharpBefore, faSharpDown, faSharpAfter);
      faInt.scale.set(.5, .5, .5);
      faInt.rotation.x = 1;
      const fa_ = new THREE.Group().add(faTouch, faInt);
      fa_.name = `fa${i}`;

      // SOL
      const solDown = this.touchDown.clone();
      solDown.position.set((this.touchX+this.touchInt)*4+this.touchX/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const solUp = this.touch2Up.clone();
      solUp.position.set(this.touchX*4+this.touchInt*4.5+this.sharpX/2+this.touch2UpX/2, this.touchPositionY, this.touchUpZ/2);
      const solTouch = new THREE.Group().add(solDown, solUp);
      solTouch.scale.set(.5, .5, .5);
      solTouch.rotation.x = 1;
      // SOL - Int
      const solLaTouchInt = this.touchDownInt.clone();
      solLaTouchInt.position.set(this.touchX*5+this.touchInt*4.5, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const solSharpBefore = this.sharpBefore.clone();
      solSharpBefore.position.set(this.touchX*5 + this.touchInt*4 - this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const solSharpDown = this.sharpDown.clone();
      solSharpDown.position.set(this.touchX*5 + this.touchInt*4.5, this.touchPositionY, this.sharpZ+this.touchInt/2);
      const solSharpAfter = this.sharpAfter.clone();
      solSharpAfter.position.set((this.touchX+this.touchInt)*5 + this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const solInt = new THREE.Group().add(solLaTouchInt, solSharpBefore, solSharpDown, solSharpAfter);
      solInt.scale.set(.5, .5, .5);
      solInt.rotation.x = 1;
      const sol_ = new THREE.Group().add(solTouch, solInt);
      sol_.name = `sol${i}`;

      // LA
      const laDown = this.touchDown.clone();
      laDown.position.set((this.touchX+this.touchInt)*5+this.touchX/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const laUp = this.touch2Up.clone();
      laUp.position.set(this.touchX*5+this.touchInt*5.5+this.sharpX/2+this.touch2UpX/2, this.touchPositionY, this.touchUpZ/2);
      const laTouch = new THREE.Group().add(laDown, laUp);
      laTouch.scale.set(.5, .5, .5);
      laTouch.rotation.x = 1;
      // LA - Int
      const laSiTouchInt = this.touchDownInt.clone();
      laSiTouchInt.position.set(this.touchX*6+this.touchInt*5.5, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const laSharpBefore = this.sharpBefore.clone();
      laSharpBefore.position.set(this.touchX*6 + this.touchInt*5 - this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const laSharpDown = this.sharpDown.clone();
      laSharpDown.position.set(this.touchX*6 + this.touchInt*5.5, this.touchPositionY, this.sharpZ+this.touchInt/2);
      const laSharpAfter = this.sharpAfter.clone();
      laSharpAfter.position.set((this.touchX+this.touchInt)*6 + this.sharpX/2, this.touchPositionY, this.touchUpZ/2);
      const laInt = new THREE.Group().add(laSiTouchInt, laSharpBefore, laSharpDown, laSharpAfter);
      laInt.scale.set(.5, .5, .5);
      laInt.rotation.x = 1;
      const la_ = new THREE.Group().add(laTouch, laInt);
      la_.name = `la${i}`;

      // SI
      const siDown = this.touchDown.clone();
      siDown.position.set((this.touchX+this.touchInt)*6+this.touchX/2, this.touchPositionY, this.touchZ - this.touchDownZ/2);
      const siUp = this.touch1Up.clone();
      siUp.position.set(this.touchX*6+this.touchInt*6.5+this.sharpX/2+this.touch1UpX/2, this.touchPositionY, this.touchUpZ/2);
      const siTouch = new THREE.Group().add(siDown, siUp);
      siTouch.scale.set(.5, .5, .5);
      siTouch.rotation.x = 1;
      // SI - Int
      const siDoTouchInt = this.touchFullInt.clone();
      siDoTouchInt.position.set(this.touchX*7+this.touchInt*6.5, this.touchPositionY, this.touchZ/2);
      const siInt = new THREE.Group().add(siDoTouchInt);
      siInt.scale.set(.5, .5, .5);
      siInt.rotation.x = 1;
      const si_ = new THREE.Group().add(siTouch, siInt);
      si_.name = `si${i}`;

      /*
       * SHARPS
       */

      // DO_SHARP
      const doSharpUp = this.sharpUp.clone();
      doSharpUp.position.set(this.touchX+this.touchInt/2, this.sharpPositionY, this.sharpZ/2);
      const doSharpTouch = new THREE.Group().add(doSharpUp);
      doSharpTouch.scale.set(.5, .5, .5);
      doSharpTouch.rotation.x = 1;
      const doSharp = new THREE.Group().add(doSharpTouch);

      // RE_SHARP
      const reSharpUp = this.sharpUp.clone();
      reSharpUp.position.set(this.touchX*2+this.touchInt*1.5, this.sharpPositionY, this.sharpZ/2);
      const reSharpTouch = new THREE.Group().add(reSharpUp);
      reSharpTouch.scale.set(.5, .5, .5);
      reSharpTouch.rotation.x = 1;
      const reSharp = new THREE.Group().add(reSharpTouch);

      // FA_SHARP
      const faSharpUp = this.sharpUp.clone();
      faSharpUp.position.set(this.touchX*4+this.touchInt*3.5, this.sharpPositionY, this.sharpZ/2);
      const faSharpTouch = new THREE.Group().add(faSharpUp);
      faSharpTouch.scale.set(.5, .5, .5);
      faSharpTouch.rotation.x = 1;
      const faSharp = new THREE.Group().add(faSharpTouch);

      // SOL_SHARP
      const solSharpUp = this.sharpUp.clone();
      solSharpUp.position.set(this.touchX*5+this.touchInt*4.5, this.sharpPositionY, this.sharpZ/2);
      const solSharpTouch = new THREE.Group().add(solSharpUp);
      solSharpTouch.scale.set(.5, .5, .5);
      solSharpTouch.rotation.x = 1;
      const solSharp = new THREE.Group().add(solSharpTouch);

      // LA_SHARP
      const laSharpUp = this.sharpUp.clone();
      laSharpUp.position.set(this.touchX*6+this.touchInt*5.5, this.sharpPositionY, this.sharpZ/2);
      const laSharpTouch = new THREE.Group().add(laSharpUp);
      laSharpTouch.scale.set(.5, .5, .5);
      laSharpTouch.rotation.x = 1;
      const laSharp = new THREE.Group().add(laSharpTouch);

      const sharps = new THREE.Group().add(doSharp, reSharp, faSharp, solSharp, laSharp);
      sharps.name = `sharps${i}`;

      const octave: THREE.Group = new THREE.Group().add(do_, re_, mi_, fa_, sol_, la_, si_, sharps);
      octave.name = `octave${i}`;

      this.piano.add(octave);

      switch (i) {
        case 0: this.octave0 = octave;
          this.octave0.position.x = octavePosition;
          this.do0 = doTouch;
          this.do0Sharp = doSharp;
          this.re0 = reTouch;
          this.re0Sharp = reSharp;
          this.mi0 = miTouch;
          this.fa0 = faTouch;
          this.fa0Sharp = faSharp;
          this.sol0 = solTouch;
          this.sol0Sharp = solSharp;
          this.la0 = laTouch;
          this.la0Sharp = laSharp;
          this.si0 = siTouch;
          break;
        case 1: this.octave1 = octave;
          this.octave1.position.x = octavePosition;
          this.do1 = doTouch;
          this.do1Sharp = doSharp;
          this.re1 = reTouch;
          this.re1Sharp = reSharp;
          this.mi1 = miTouch;
          this.fa1 = faTouch;
          this.fa1Sharp = faSharp;
          this.sol1 = solTouch;
          this.sol1Sharp = solSharp;
          this.la1 = laTouch;
          this.la1Sharp = laSharp;
          this.si1 = siTouch;
          break;
        case 2: this.octave2 = octave;
          this.octave2.position.x = octavePosition;
          this.do2 = doTouch;
          this.do2Sharp = doSharp;
          this.re2 = reTouch;
          this.re2Sharp = reSharp;
          this.mi2 = miTouch;
          this.fa2 = faTouch;
          this.fa2Sharp = faSharp;
          this.sol2 = solTouch;
          this.sol2Sharp = solSharp;
          this.la2 = laTouch;
          this.la2Sharp = laSharp;
          this.si2 = siTouch;
          break;
        case 3: this.octave3 = octave;
          this.octave3.position.x = octavePosition;
          this.do3 = doTouch;
          this.do3Sharp = doSharp;
          this.re3 = reTouch;
          this.re3Sharp = reSharp;
          this.mi3 = miTouch;
          this.fa3 = faTouch;
          this.fa3Sharp = faSharp;
          this.sol3 = solTouch;
          this.sol3Sharp = solSharp;
          this.la3 = laTouch;
          this.la3Sharp = laSharp;
          this.si3 = siTouch;
          break;
        case 4: this.octave4 = octave;
          this.octave4.position.x = octavePosition;
          this.do4 = doTouch;
          this.do4Sharp = doSharp;
          this.re4 = reTouch;
          this.re4Sharp = reSharp;
          this.mi4 = miTouch;
          this.fa4 = faTouch;
          this.fa4Sharp = faSharp;
          this.sol4 = solTouch;
          this.sol4Sharp = solSharp;
          this.la4 = laTouch;
          this.la4Sharp = laSharp;
          this.si4 = siTouch;
          break;
        case 5: this.octave5 = octave;
          this.octave5.position.x = octavePosition;
          this.do5 = doTouch;
          this.do5Sharp = doSharp;
          this.re5 = reTouch;
          this.re5Sharp = reSharp;
          this.mi5 = miTouch;
          this.fa5 = faTouch;
          this.fa5Sharp = faSharp;
          this.sol5 = solTouch;
          this.sol5Sharp = solSharp;
          this.la5 = laTouch;
          this.la5Sharp = laSharp;
          this.si5 = siTouch;
          break;
        case 6: this.octave6 = octave;
          this.octave6.position.x = octavePosition;
          this.do6 = doTouch;
          this.do6Sharp = doSharp;
          this.re6 = reTouch;
          this.re6Sharp = reSharp;
          this.mi6 = miTouch;
          this.fa6 = faTouch;
          this.fa6Sharp = faSharp;
          this.sol6 = solTouch;
          this.sol6Sharp = solSharp;
          this.la6 = laTouch;
          this.la6Sharp = laSharp;
          this.si6 = siTouch;
          break;
      }

      octavePosition += ((this.touchX + this.touchInt)*7)/2;
    }
  }

  /**
   * Octave 7 - Do 7
   */

  private do7: THREE.Group = new THREE.Group();

  private octave7() {
    // DO 7
    const do7Down = this.fullTouch.clone();
    do7Down.position.set(this.touchX/2, this.touchPositionY, this.touchZ - this.touchZ/2);
    const do7Touch = new THREE.Group().add(do7Down);
    do7Touch.scale.set(.5, .5, .5);
    do7Touch.rotation.x = 1;
    // DO 7 - Int
    const do7TouchInt = this.touchFullInt.clone();
    do7TouchInt.position.set(this.touchX+this.touchInt/2, this.touchPositionY, this.touchZ/2);
    const do7Int = new THREE.Group().add(do7TouchInt);
    do7Int.scale.set(.5, .5, .5);
    do7Int.rotation.x = 1;
    const do7_ = new THREE.Group().add(do7Touch, do7Int);
    do7_.name = 'do7';

    this.do7 = do7Touch;

    const octave7: THREE.Group = new THREE.Group().add(do7_);
    octave7.position.x = ((this.touchX+this.touchInt)*7)*3.5;
    octave7.name = 'octave7';

    this.piano.add(octave7);
  }

  private renderer!: THREE.WebGLRenderer;
  private scene!: THREE.Scene;

  /**
   * Piano Scene
   * @memberOf PianoAppComponent
   */
  private pianoScene() {
    // Scene
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
    this.scene.add(this.piano);

    this.octave_1();

    this.octaves();

    this.octave7();

    /** Stage Properties **/

    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;

    const fieldOfView: number = 75;
    const aspectRatio: number = canvasWidth/canvasHeight;
    const nearClippingPlane: number = 1;
    const farClippingPlane: number = 1000;
    const cameraPosX: number = 28;
    const cameraPosY: number = -5;
    const cameraPosZ: number = 25;
    const cameraRotX: number = 0;
    const cameraRotY: number = 0;
    const cameraRotZ: number = 0.11;

    this.camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearClippingPlane,
      farClippingPlane
    );

    this.camera.position.set(cameraPosX, cameraPosY, cameraPosZ);
    this.camera.rotation.set(cameraRotX, cameraRotY, cameraRotZ);
  }

  /**
   * Animate the piano / play music!
   *
   * @private
   * @memberOf PianoAppComponent
   */
  private animatePiano = async () => {
    const tempo: number = 60 / 59;
    const sixteenth: number = tempo / 4;
    const eighth: number = tempo / 2;
    const quarter: number = tempo;
    const start: number = 1;

    const touchRotation0 = 1;
    const touchRotation1 = 1.15;
    const sharpRotation0 = 0;
    const sharpRotation1 = .5;

    // Audio
    const mi1Play = () => this.audioService.mi1.play();
    const mi1Pause = () => this.audioService.mi1.pause();
    const la1Play = () => this.audioService.la1.play();
    const la1Pause = () => this.audioService.la1.pause();

    const mi2Play = () => this.audioService.mi2.play();
    const mi2Pause = () => this.audioService.mi2.pause();
    const sol2SharpPlay = () => this.audioService.sol2Sharp.play();
    const sol2SharpPause = () => this.audioService.sol2Sharp.pause();
    const la2Play = () => this.audioService.la2.play();
    const la2Pause = () => this.audioService.la2.pause();

    const do3Play = () => this.audioService.do3.play();
    const do3Pause = () => this.audioService.do3.pause();
    const mi3Play = () => this.audioService.mi3.play();
    const mi3Pause = () => this.audioService.mi3.pause();
    const sol3SharpPlay = () => this.audioService.sol3Sharp.play();
    const sol3SharpPause = () => this.audioService.sol3Sharp.pause();
    const la3Play = () => this.audioService.la3.play();
    const la3Pause = () => this.audioService.la3.pause();
    const si3Play = () => this.audioService.si3.play();
    const si3Pause = () => this.audioService.si3.pause();

    const do4Play = () => this.audioService.do4.play();
    const do4Pause = () => this.audioService.do4.pause();
    const re4Play = () => this.audioService.re4.play();
    const re4Pause = () => this.audioService.re4.pause();
    const re4SharpPlay = () => this.audioService.re4Sharp.play();
    const re4SharpPause = () => this.audioService.re4Sharp.pause();

    const mi4Play = () => this.audioService.mi4.play();
    const mi4Pause = () => this.audioService.mi4.pause();

    this.musicPlaying = true;
    this.onOffSound();

    const furElise = () => {
      gsap.to(this.mi4.rotation, {
        duration: sixteenth,
        delay: start,
        x: touchRotation1,
        onStart: mi4Play,
        onComplete: mi4Pause
      })
      gsap.to(this.mi4.rotation, {duration: sixteenth, delay: (start + sixteenth), x: touchRotation0});
      gsap.to(this.re4Sharp.rotation, {
        duration: sixteenth,
        delay: (start + sixteenth),
        x: sharpRotation1,
        onStart: re4SharpPlay,
        onComplete: re4SharpPause
      });
      gsap.to(this.re4Sharp.rotation, {duration: sixteenth, delay: (start + sixteenth * 2), x: sharpRotation0});
      gsap.to(this.mi4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 2,
        x: touchRotation1,
        onStart: mi4Play,
        onComplete: mi4Pause
      });
      gsap.to(this.mi4.rotation, {duration: sixteenth, delay: start + sixteenth * 3, x: touchRotation0});
      gsap.to(this.re4Sharp.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 3,
        x: sharpRotation1,
        onStart: re4SharpPlay,
        onComplete: re4SharpPause
      });
      gsap.to(this.re4Sharp.rotation, {duration: sixteenth, delay: start + sixteenth * 4, x: sharpRotation0});
      gsap.to(this.mi4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 4,
        x: touchRotation1,
        onStart: mi4Play,
        onComplete: mi4Pause
      });
      gsap.to(this.mi4.rotation, {duration: sixteenth, delay: start + sixteenth * 5, x: touchRotation0});
      gsap.to(this.si3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 5,
        x: touchRotation1,
        onStart: si3Play,
        onComplete: si3Pause
      });
      gsap.to(this.si3.rotation, {duration: sixteenth, delay: start + sixteenth * 6, x: touchRotation0});
      gsap.to(this.re4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 6,
        x: touchRotation1,
        onStart: re4Play,
        onComplete: re4Pause
      });
      gsap.to(this.re4.rotation, {duration: sixteenth, delay: start + sixteenth * 7, x: touchRotation0});
      gsap.to(this.do4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 7,
        x: touchRotation1,
        onStart: do4Play,
        onComplete: do4Pause
      });
      gsap.to(this.do4.rotation, {duration: sixteenth, delay: start + sixteenth * 8, x: touchRotation0});
      gsap.to(this.la3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 8,
        x: touchRotation1,
        onStart: la3Play,
        onComplete: la3Pause
      });
      gsap.to(this.la3.rotation, {duration: eighth, delay: start + sixteenth * 8 + eighth, x: touchRotation0});
      gsap.to(this.la1.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 8,
        x: touchRotation1,
        onStart: la1Play,
        onComplete: la1Play
      }); //fa
      gsap.to(this.la1.rotation, {duration: sixteenth, delay: start + sixteenth * 9, x: touchRotation0}); //fa
      gsap.to(this.mi2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 9,
        x: touchRotation1,
        onStart: mi2Play,
        onComplete: mi2Pause
      }); //fa
      gsap.to(this.mi2.rotation, {duration: sixteenth, delay: start + sixteenth * 10, x: touchRotation0}); //fa
      gsap.to(this.la2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 10,
        x: touchRotation1,
        onStart: la2Play,
        onComplete: la2Pause
      }); //fa
      gsap.to(this.la2.rotation, {duration: sixteenth, delay: start + sixteenth * 11, x: touchRotation0}); //fa

      gsap.to(this.do3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 11,
        x: touchRotation1,
        onStart: do3Play,
        onComplete: do3Pause
      });
      gsap.to(this.do3.rotation, {duration: sixteenth, delay: start + sixteenth * 12, x: touchRotation0});
      gsap.to(this.mi3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 12,
        x: touchRotation1,
        onStart: mi3Play,
        onComplete: mi3Pause
      });
      gsap.to(this.mi3.rotation, {duration: sixteenth, delay: start + sixteenth * 13, x: touchRotation0});
      gsap.to(this.la3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 13,
        x: touchRotation1,
        onStart: la3Play,
        onComplete: la3Pause
      });
      gsap.to(this.la3.rotation, {duration: sixteenth, delay: start + sixteenth * 14, x: touchRotation0});
      gsap.to(this.si3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 14,
        x: touchRotation1,
        onStart: si3Play,
        onComplete: si3Pause
      });
      gsap.to(this.si3.rotation, {duration: eighth, delay: start + sixteenth * 14 + eighth, x: touchRotation0});
      gsap.to(this.mi1.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 14,
        x: touchRotation1,
        onStart: mi1Play,
        onComplete: mi1Pause
      }); //fa
      gsap.to(this.mi1.rotation, {duration: sixteenth, delay: start + sixteenth * 15, x: touchRotation0}); //fa
      gsap.to(this.mi2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 15,
        x: touchRotation1,
        onStart: mi2Play,
        onComplete: mi2Pause
      }); //fa
      gsap.to(this.mi2.rotation, {duration: sixteenth, delay: start + sixteenth * 16, x: touchRotation0}); //fa
      gsap.to(this.sol2Sharp.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 16,
        x: sharpRotation1,
        onStart: sol2SharpPlay,
        onComplete: sol2SharpPause
      }); //fa
      gsap.to(this.sol2Sharp.rotation, {duration: sixteenth, delay: start + sixteenth * 17, x: sharpRotation0}); //fa

      gsap.to(this.mi3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 17,
        x: touchRotation1,
        onStart: mi3Play,
        onComplete: mi3Pause
      });
      gsap.to(this.mi3.rotation, {duration: sixteenth, delay: start + sixteenth * 18, x: touchRotation0});
      gsap.to(this.sol3Sharp.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 18,
        x: sharpRotation1,
        onStart: sol3SharpPlay,
        onComplete: sol3SharpPause
      });
      gsap.to(this.sol3Sharp.rotation, {duration: sixteenth, delay: start + sixteenth * 19, x: sharpRotation0});
      gsap.to(this.si3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 19,
        x: touchRotation1,
        onStart: si3Play,
        onComplete: si3Pause
      });
      gsap.to(this.si3.rotation, {duration: sixteenth, delay: start + sixteenth * 20, x: touchRotation0});
      gsap.to(this.do4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 20,
        x: touchRotation1,
        onStart: do4Play,
        onComplete: do4Pause
      });
      gsap.to(this.do4.rotation, {duration: eighth, delay: start + sixteenth * 20 + eighth, x: touchRotation0});
      gsap.to(this.la1.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 20,
        x: touchRotation1,
        onStart: la1Play,
        onComplete: la1Pause
      }); //fa
      gsap.to(this.la1.rotation, {duration: sixteenth, delay: start + sixteenth * 21, x: touchRotation0}); //fa
      gsap.to(this.mi2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 21,
        x: touchRotation1,
        onStart: mi2Play,
        onComplete: mi2Pause
      }); //fa
      gsap.to(this.mi2.rotation, {duration: sixteenth, delay: start + sixteenth * 22, x: touchRotation0}); //fa
      gsap.to(this.la2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 22,
        x: touchRotation1,
        onStart: la2Play,
        onComplete: la2Pause
      }); //fa
      gsap.to(this.la2.rotation, {duration: sixteenth, delay: start + sixteenth * 23, x: touchRotation0}); //fa

      gsap.to(this.mi3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 23,
        x: touchRotation1,
        onStart: mi3Play,
        onComplete: mi3Pause
      });
      gsap.to(this.mi3.rotation, {duration: sixteenth, delay: start + sixteenth * 24, x: touchRotation0});
      gsap.to(this.mi4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 24,
        x: touchRotation1,
        onStart: mi4Play,
        onComplete: mi4Pause
      });
      gsap.to(this.mi4.rotation, {duration: sixteenth, delay: start + sixteenth * 25, x: touchRotation0});
      gsap.to(this.re4Sharp.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 25,
        x: sharpRotation1,
        onStart: re4SharpPlay,
        onComplete: re4SharpPause
      });
      gsap.to(this.re4Sharp.rotation, {duration: sixteenth, delay: start + sixteenth * 26, x: sharpRotation0});
      gsap.to(this.mi4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 26,
        x: touchRotation1,
        onStart: mi4Play,
        onComplete: mi4Pause
      });
      gsap.to(this.mi4.rotation, {duration: sixteenth, delay: start + sixteenth * 27, x: touchRotation0});
      gsap.to(this.re4Sharp.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 27,
        x: sharpRotation1,
        onStart: re4SharpPlay,
        onComplete: re4SharpPause
      });
      gsap.to(this.re4Sharp.rotation, {duration: sixteenth, delay: start + sixteenth * 28, x: sharpRotation0});
      gsap.to(this.mi4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 28,
        x: touchRotation1,
        onStart: mi4Play,
        onComplete: mi4Pause
      });
      gsap.to(this.mi4.rotation, {duration: sixteenth, delay: start + sixteenth * 29, x: touchRotation0});
      gsap.to(this.si3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 29,
        x: touchRotation1,
        onStart: si3Play,
        onComplete: si3Pause
      });
      gsap.to(this.si3.rotation, {duration: sixteenth, delay: start + sixteenth * 30, x: touchRotation0});
      gsap.to(this.re4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 30,
        x: touchRotation1,
        onStart: re4Play,
        onComplete: re4Pause
      });
      gsap.to(this.re4.rotation, {duration: sixteenth, delay: start + sixteenth * 31, x: touchRotation0});
      gsap.to(this.do4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 31,
        x: touchRotation1,
        onStart: do4Play,
        onComplete: do4Pause
      });
      gsap.to(this.do4.rotation, {duration: sixteenth, delay: start + sixteenth * 32, x: touchRotation0});
      gsap.to(this.la3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 32,
        x: touchRotation1,
        onStart: la3Play,
        onComplete: la3Pause
      });
      gsap.to(this.la3.rotation, {duration: eighth, delay: start + sixteenth * 32 + eighth, x: touchRotation0});
      gsap.to(this.la1.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 32,
        x: touchRotation1,
        onStart: la1Play,
        onComplete: la1Pause
      }); //fa
      gsap.to(this.la1.rotation, {duration: sixteenth, delay: start + sixteenth * 33, x: touchRotation0}); //fa
      gsap.to(this.mi2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 33,
        x: touchRotation1,
        onStart: mi2Play,
        onComplete: mi2Pause
      }); //fa
      gsap.to(this.mi2.rotation, {duration: sixteenth, delay: start + sixteenth * 34, x: touchRotation0}); //fa
      gsap.to(this.la2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 34,
        x: touchRotation1,
        onStart: la2Play,
        onComplete: la2Pause
      }); //fa
      gsap.to(this.la2.rotation, {duration: sixteenth, delay: start + sixteenth * 35, x: touchRotation0}); //fa

      gsap.to(this.do3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 35,
        x: touchRotation1,
        onStart: do3Play,
        onComplete: do3Pause
      });
      gsap.to(this.do3.rotation, {duration: sixteenth, delay: start + sixteenth * 36, x: touchRotation0});
      gsap.to(this.mi3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 36,
        x: touchRotation1,
        onStart: mi3Play,
        onComplete: mi3Pause
      });
      gsap.to(this.mi3.rotation, {duration: sixteenth, delay: start + sixteenth * 37, x: touchRotation0});
      gsap.to(this.la3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 37,
        x: touchRotation1,
        onStart: la3Play,
        onComplete: la3Pause
      });
      gsap.to(this.la3.rotation, {duration: sixteenth, delay: start + sixteenth * 38, x: touchRotation0});
      gsap.to(this.si3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 38,
        x: touchRotation1,
        onStart: si3Play,
        onComplete: si3Pause
      });
      gsap.to(this.si3.rotation, {duration: eighth, delay: start + sixteenth * 38 + eighth, x: touchRotation0});
      gsap.to(this.mi1.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 38,
        x: touchRotation1,
        onStart: mi1Play,
        onComplete: mi1Pause
      }); //fa
      gsap.to(this.mi1.rotation, {duration: sixteenth, delay: start + sixteenth * 39, x: touchRotation0}); //fa
      gsap.to(this.mi2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 39,
        x: touchRotation1,
        onStart: mi2Play,
        onComplete: mi2Pause
      }); //fa
      gsap.to(this.mi2.rotation, {duration: sixteenth, delay: start + sixteenth * 40, x: touchRotation0}); //fa
      gsap.to(this.sol2Sharp.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 40,
        x: sharpRotation1,
        onStart: sol2SharpPlay,
        onComplete: sol2SharpPause
      }); //fa
      gsap.to(this.sol2Sharp.rotation, {duration: sixteenth, delay: start + sixteenth * 41, x: sharpRotation0}); //fa

      gsap.to(this.mi3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 41,
        x: touchRotation1,
        onStart: mi3Play,
        onComplete: mi3Pause
      });
      gsap.to(this.mi3.rotation, {duration: sixteenth, delay: start + sixteenth * 42, x: touchRotation0});
      gsap.to(this.do4.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 42,
        x: touchRotation1,
        onStart: do4Play,
        onComplete: do4Pause
      });
      gsap.to(this.do4.rotation, {duration: sixteenth, delay: start + sixteenth * 43, x: touchRotation0});
      gsap.to(this.si3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 43,
        x: touchRotation1,
        onStart: si3Play,
        onComplete: si3Pause
      });
      gsap.to(this.si3.rotation, {duration: sixteenth, delay: start + sixteenth * 44, x: touchRotation0});
      gsap.to(this.la3.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 44,
        x: touchRotation1,
        onStart: la3Play,
        onComplete: la3Pause
      });
      gsap.to(this.la3.rotation, {duration: quarter, delay: start + sixteenth * 44 + quarter, x: touchRotation0});
      gsap.to(this.la1.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 44,
        x: touchRotation1,
        onStart: la1Play,
        onComplete: la1Pause
      }); //fa
      gsap.to(this.la1.rotation, {duration: sixteenth, delay: start + sixteenth * 45, x: touchRotation0}); //fa
      gsap.to(this.mi2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 45,
        x: touchRotation1,
        onStart: mi2Play,
        onComplete: mi2Pause
      }); //fa
      gsap.to(this.mi2.rotation, {duration: sixteenth, delay: start + sixteenth * 46, x: touchRotation0}); //fa
      gsap.to(this.la2.rotation, {
        duration: sixteenth,
        delay: start + sixteenth * 46,
        x: touchRotation1,
        onStart: la2Play,
        onComplete: la2Pause
      }); //fa
      gsap.to(this.la2.rotation, {duration: sixteenth, delay: start + sixteenth * 47, x: touchRotation0}); //fa
    }
    await furElise();

    const timeEnd = ((start + sixteenth * 47) + sixteenth) * 1000;

    setTimeout(() => {
      this.musicPlaying = false;
      this.onOffSound();
    }, timeEnd);

  }

  /**
   * Rendering loop
   *
   * @private
   * @memberOf PianoAppComponent
   */
  private renderingLoop() {
    // Renderer
    this.renderer = new THREE.WebGLRenderer({canvas: this.canvas});
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);

    let component: PianoAppComponent = this;
    (function render() {
      requestAnimationFrame(render);
      component.renderer.render(component.scene, component.camera);
    }());
  }

  ngAfterViewInit() {
    this.pianoScene();
    this.renderingLoop();
  }
}
