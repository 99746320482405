<canvas #canvas id="canvas"></canvas>

<button
  id="audioBtn"
  [ngClass]="['audioBtn', 'control-power', zindex]"
  role="switch"
  aria-checked="false"
  data-power="on"
  (click)="playPiano()"
>
  <span id="icon-music" class="fa fa-play" aria-label="Jouer"></span>
  <p class="audioBtn-label text-12">Für Elise</p>
</button>
