import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  constructor() { }

  public la_1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a-1.ogg');
  public la_1Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a-1Sharp.ogg');
  public si_1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b-1.ogg');

  public do0 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c0.ogg');
  public do0Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/c0Sharp.ogg');
  public re0 = new Audio('../../../assets/sounds/piano-keys-long-reverb/d0.ogg');
  public re0Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/d0Sharp.ogg');
  public mi0 = new Audio('../../../assets/sounds/piano-keys-long-reverb/e0.ogg');
  public fa0 = new Audio('../../../assets/sounds/piano-keys-long-reverb/f0.ogg');
  public fa0Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/f0Sharp.ogg');
  public sol0 = new Audio('../../../assets/sounds/piano-keys-long-reverb/g0.ogg');
  public sol0Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/g0Sharp.ogg');
  public la0 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a0.ogg');
  public la0Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a0Sharp.ogg');
  public si0 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b0.ogg');

  public do1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c1.ogg');
  public do1Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/c1Sharp.ogg');
  public re1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/d1.ogg');
  public re1Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/d1Sharp.ogg');
  public mi1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/e1.ogg');
  public fa1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/f1.ogg');
  public fa1Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/f1Sharp.ogg');
  public sol1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/g1.ogg');
  public sol1Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/g1Sharp.ogg');
  public la1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a1.ogg');
  public la1Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a1Sharp.ogg');
  public si1 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b1.ogg');

  public do2 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c2.ogg');
  public do2Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/c2Sharp.ogg');
  public re2 = new Audio('../../../assets/sounds/piano-keys-long-reverb/d2.ogg');
  public re2Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/d2Sharp.ogg');
  public mi2 = new Audio('../../../assets/sounds/piano-keys-long-reverb/e2.ogg');
  public fa2 = new Audio('../../../assets/sounds/piano-keys-long-reverb/f2.ogg');
  public fa2Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/f2Sharp.ogg');
  public sol2 = new Audio('../../../assets/sounds/piano-keys-long-reverb/g2.ogg');
  public sol2Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/g2Sharp.ogg');
  public la2 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a2.ogg');
  public la2Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a2Sharp.ogg');
  public si2 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b2.ogg');

  public do3 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c3.ogg');
  public do3Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/c3Sharp.ogg');
  public re3 = new Audio('../../../assets/sounds/piano-keys-long-reverb/d3.ogg');
  public re3Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/d3Sharp.ogg');
  public mi3 = new Audio('../../../assets/sounds/piano-keys-long-reverb/e3.ogg');
  public fa3 = new Audio('../../../assets/sounds/piano-keys-long-reverb/f3.ogg');
  public fa3Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/f3Sharp.ogg');
  public sol3 = new Audio('../../../assets/sounds/piano-keys-long-reverb/g3.ogg');
  public sol3Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/g3Sharp.ogg');
  public la3 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a3.ogg');
  public la3Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a3Sharp.ogg');
  public si3 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b3.ogg');

  public do4 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c4.ogg');
  public do4Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/c4Sharp.ogg');
  public re4 = new Audio('../../../assets/sounds/piano-keys-long-reverb/d4.ogg');
  public re4Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/d4Sharp.ogg');
  public mi4 = new Audio('../../../assets/sounds/piano-keys-long-reverb/e4.ogg');
  public fa4 = new Audio('../../../assets/sounds/piano-keys-long-reverb/f4.ogg');
  public fa4Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/f4Sharp.ogg');
  public sol4 = new Audio('../../../assets/sounds/piano-keys-long-reverb/g4.ogg');
  public sol4Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/g4Sharp.ogg');
  public la4 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a4.ogg');
  public la4Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a4Sharp.ogg');
  public si4 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b4.ogg');

  public do5 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c5.ogg');
  public do5Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/c5Sharp.ogg');
  public re5 = new Audio('../../../assets/sounds/piano-keys-long-reverb/d5.ogg');
  public re5Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/d5Sharp.ogg');
  public mi5 = new Audio('../../../assets/sounds/piano-keys-long-reverb/e5.ogg');
  public fa5 = new Audio('../../../assets/sounds/piano-keys-long-reverb/f5.ogg');
  public fa5Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/f5Sharp.ogg');
  public sol5 = new Audio('../../../assets/sounds/piano-keys-long-reverb/g5.ogg');
  public sol5Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/g5Sharp.ogg');
  public la5 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a5.ogg');
  public la5Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a5Sharp.ogg');
  public si5 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b5.ogg');

  public do6 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c6.ogg');
  public do6Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/c6Sharp.ogg');
  public re6 = new Audio('../../../assets/sounds/piano-keys-long-reverb/d6.ogg');
  public re6Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/d6Sharp.ogg');
  public mi6 = new Audio('../../../assets/sounds/piano-keys-long-reverb/e6.ogg');
  public fa6 = new Audio('../../../assets/sounds/piano-keys-long-reverb/f6.ogg');
  public fa6Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/f6Sharp.ogg');
  public sol6 = new Audio('../../../assets/sounds/piano-keys-long-reverb/g6.ogg');
  public sol6Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/g6Sharp.ogg');
  public la6 = new Audio('../../../assets/sounds/piano-keys-long-reverb/a6.ogg');
  public la6Sharp = new Audio('../../../assets/sounds/piano-keys-long-reverb/a6Sharp.ogg');
  public si6 = new Audio('../../../assets/sounds/piano-keys-long-reverb/b6.ogg');

  public do7 = new Audio('../../../assets/sounds/piano-keys-long-reverb/c7.ogg');
}

