import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from "@angular/router";
import { fadeInAnimation } from "../../animations";

@Component({
  selector: 'elapp-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [fadeInAnimation]
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animationState'];
  }

}
