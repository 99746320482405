<nav role="navigation">
  <a class="menu"
     [attr.aria-expanded]="this.expanded"
     aria-haspopup="dialog"
     aria-controls="menu"
     (click)="toOpenClose()"
     tabindex="1">
    <div class="menu-icon">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>
  </a>
  <elapp-social></elapp-social>
  <div id="menu"
       #menu
       [className]="opened">
    <a class="outer"
       (click)="toOpenClose()"
       tabindex="1">
      <div class="inner">
        <label>Fermer</label>
      </div>
    </a>
    <ul class="app-list">
      <li class="home">
        <a title="Elapp Home"
           routerLink="/home"
           routerLinkActive="active"
           (click)="toOpenClose()"
           tabindex="1">
          <img src="assets/icons/elapp.svg" alt="Elapp Home" role="img" aria-hidden="true">
          <label>Home</label>
        </a>
      </li>
      <!--
      <li class="wedding">
        <a title="EA Wedding"
           href="https://wedding.elapp.fr"
           (click)="toOpenClose('wedding-app')"
           (keypress)="toOpenClose('wedding-app')"
           tabindex="1">
          <img src="assets/icons/wedding.svg" alt="Wedding App" role="img" aria-hidden="true">
          <label>Wedding App</label>
        </a>
      </li>
      -->
      <li class="btd">
        <a title="Beauté Divine Institut"
           href="/"
           (click)="toOpenClose()"
           tabindex="1">
          <img src="assets/icons/btd.svg" alt="Beauté Divine App" role="img" aria-hidden="true">
          <label>Beauté Divine App</label>
        </a>
      </li>
    </ul>
  </div>
</nav>
