import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'elapp-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {

  @ViewChild('menu') menuRef: ElementRef;

  expanded: boolean = false;
  opened: string = 'close';
  fragment: string = 'menu';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.toOpenClose();
  }

  toOpenClose(page?: string): void {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.opened = 'close';
      let audioBtnClass = document.getElementById('audioBtn').className;
      document.getElementById('audioBtn').className = audioBtnClass.replace('z-index-0', 'z-index-1');
    } else {
      this.opened = 'opened';
      let audioBtnClass = document.getElementById('audioBtn').className;
      document.getElementById('audioBtn').className = audioBtnClass.replace('z-index-1', 'z-index-0');
    }
    //this.router.navigate([`/${page}`]);
  }
}
