<div class="socialBar">
  <p class="socialBar-title">On the air</p>
  <span class="socialBar-line"></span>
  <ul class="socialBar-list">
    <li>
      <a title="LinkedIn"
         href="https://www.linkedin.com/in/%C3%A9lise-martin-98ab43b2/"
         target="_blank">
        <span class="fa fa-linkedin" aria-label="LinkedIn : Nouvelle fenêtre"></span>
      </a>
    </li>
    <li>
      <a title="GitLab"
         href="https://gitlab.com/elapp"
         target="_blank">
        <span class="fa fa-gitlab" aria-label="GitLab : Nouvelle fenêtre"></span>
      </a>
    </li>
    <li>
      <a title="Facebook"
         href="https://www.facebook.com/elise.martin.923171/"
         target="_blank">
        <span class="fa fa-facebook-f" aria-label="Facebook : Nouvelle fenêtre"></span>
      </a>
    </li>
    <li>
      <a title="Instagram"
         href="https://www.instagram.com/elise.l.abeille/"
         target="_blank">
        <span class="fa fa-instagram" aria-label="Instagram : Nouvelle fenêtre"></span>
      </a>
    </li>
    <li>
      <a title="M'envoyer un email"
         href="mailto:elise.martin@me.com"
         class="letter-image" >
        <span class="sr-only">M'envoyer un email</span>
        <div class="animated-mail" aria-hidden="true">
          <div class="back-fold"></div>
          <div class="letter">
            <div class="letter-border"></div>
            <div class="letter-title"></div>
            <div class="letter-context"></div>
            <div class="letter-stamp">
              <div class="letter-stamp-inner"></div>
            </div>
          </div>
          <div class="top-fold"></div>
          <div class="body"></div>
          <div class="left-fold"></div>
        </div>
      </a>
    </li>
  </ul>
</div>
